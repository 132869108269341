.catalog{
  padding: 170px 0 0;
  &-row{
    @extend .row;
    margin-top: 50px;
  }
  &-col{
    @include make-col-ready();
    @include make-col(4);
    padding: 10px;
  }
  &-card{
    height: 420px;
    width: 100%;
    background-repeat: no-repeat;
    &-b{
      padding: 40px;
      font-size: 36px;
      font-weight: 600;
      color: $c-inverse;
      background: $c-primary;
    }
  }
}
@media screen and (max-width: 1024px) {
  .catalog{
    padding: 80px 0 0;
    &-card{
      height: 275px;
      background-size: 100% 100%;
      &-b{
        padding: 25px 30px;
        font-size: 23px;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .catalog{
    &-col{
      @include make-col(6);
    }
  }
}
@media screen and (max-width: 580px) {
  .catalog{
    &-row{
      margin-top: 35px;
    }
    &-col{
      @include make-col(12);
    }
    &-card{
      background-size: cover;
    }
  }
}