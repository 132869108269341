.header{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  color: $c-inverse;
  font-size: 21px;
  font-weight: 400;
  &.__fixed{
    background: rgba($c-primary-inverse, 1);
    .header{
      &-cnt{
        padding: 0;
      }
      &-l{
        align-self: center;
        &-txt{
          font-size: 18px;
          margin-left: 50px;
          opacity: .6;
        }
      }
      &-logo{
        width: 90px;
        height: 50px;
      }
      &-menu{
        padding-left: 7%;
        &-lnk{
          padding: 30px 0;
          font-size: 18px;
          &:after{
            bottom: 0;
            opacity: 1;
          }
        }
      }
      &-r{
        align-self: center;
      }
      &-btn{
        padding: 12px 35px 12px 20px;
        border-color: rgba($c-inverse, .7);
        &:hover{
          border-color: $c-primary;
        }
      }
    }
  }
  &-btn{
    &-mob{
      display: none;
      .header{
        &-btn{
          padding: 25px 50px 25px 30px;
          &:after{
            margin: auto 20px auto;
            @extend .s-arrow-primary!optional;
          }
          &:hover{
            background: $c-inverse;
            &:after{
              @extend .s-arrow-primary!optional;
            }
          }
        }
      }
    }
  }
  &-cnt{
    display: flex;
    align-items: end;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 40px 0 0;
    &.__show-menu{
      .header{
        &-logo{
          display: none;
          &.__inverse{
            display: block;
          }
        }
        &-menu{
          &-lst{
            display: block;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            max-width: 100%;
            width: 100%;
            text-align: center;
            background: $c-primary;
            z-index: 10;
          }
          &-i:first-of-type{
            margin-top: 93px;
            border-top: 1px solid rgba($c-inverse, .33);
            padding: 15px 0 0;
          }
          &-i{
            text-align: left;
            padding: 0 0;
          }
          &-lnk{
            position: relative;
            display: inline-block;
            padding: 15px 10px 15px 25px;
            font-weight: 500;
            font-size: 32px;
            text-align: left;
            &.__active{
              &:after{
                right: auto;
                bottom: 5px;
                left: 30px;
                width: 85%;
                opacity: 1;
              }
            }
          }
        }
      }
      .menu-mob{
        &:before{
          display: none;
        }
        &-ic{
          background: $c-inverse;
          &.__active{
            height: 100%;
            &:before,
            &:after{
              right: 0;
              bottom: 0;
              margin: auto;
            }
          }
        }
      }
    }
  }
  &-l{
    display: flex;
    align-items: center;
    &-txt{
      opacity: 0.33;
      margin-left: 92px;
    }
  }
  &-lnk{
    display: block;
  }
  &-logo{
    width: 126px;
    height: 75px;
    &.__inverse{
      display: none;
    }
  }
  &-menu{
    padding-left: 4%;
    flex-grow: 1;
    &-lst{
      display: flex;
    }
    &-i{
      padding: 0 3%;
      &:first-of-type{
        padding-left: 0;
      }
      &:last-of-type{
        padding-right: 0;
      }
    }
    &-lnk{
      position: relative;
      display: block;
      padding: 0 0 25px;
      &:after{
        content: '';
        position: absolute;
        display: block;
        right: 50%;
        bottom: -1px;
        left: 50%;
        height: 1px;
        width: 0;
        background-color: $c-inverse;
        transition: left .4s,right .4s;
        opacity: .2;
      }
      &.__active{
        &:after {
          width: 100%;
          left: 0;
          right: 0;
        }
      }
      &:hover{
        &:after{
          width: auto;
          left: 0;
          right: 0;
        }
      }
    }
  }
  &-btn{
    margin-left: 15px;
  }
  .main-b{
    &-r{
      display: none;
    }
  }
}

.menu-mob {
  display: none;
  position: relative;
  width: 56px;
  height: 56px;
  z-index: 11;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto 0;
  &:before{
    content: '';
    display: block;
    position: absolute;
    top: 28px;
    left: 0;
    right: 0;
    height: 1px;
    width: 20px;
    margin: 0 auto;
    background: $c-inverse;
    z-index: 1;
  }
  &-ic {
    position: relative;
    display: block;
    cursor: pointer;
    height: 100%;
    width: 100%;
    line-height: 1;
    background: $c-primary;
    border-radius: 50%;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 20px;
      left: 0;
      right: 0;
      height: 1px;
      width: 20px;
      margin: 0 auto;
      background: $c-inverse;
    }
    &:before{
      content: '';
      display: block;
      position: absolute;
      bottom: 19px;
      left: 0;
      right: 0;
      height: 1px;
      width: 20px;
      margin: 0 auto;
      background: $c-inverse;
    }
    &.__active{
      border: none;
      height: 30px;
      &:after{
        position: absolute;
        top: 0;
        right: 15px;
        width: 1px;
        height: 25px;
        background-color: $c-primary;
        transform: rotate(-45deg);
      }
      &:before{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 15px;
        width: 1px;
        height: 25px;
        background-color: $c-primary;
        transform: rotate(45deg);
      }
    }
  }
}
@media screen and (max-width: 1600px) {
  .header{
    &-l{
      &-txt{
        margin-left: 20px;
      }
    }
    &-menu{
      padding-left: 2%;
      &-lst{
        justify-content: center;
      }
      &-i{
        padding: 0 2%;
      }
    }
    &-r{
      align-self: center;
    }
  }
}
@media screen and (max-width: 1400px) {
  .header{
    &-btn{
      margin-left: 20px;
      padding: 15px 30px 15px 20px;
      &:after{
        margin: auto 15px auto;
      }
    }
  }
}
@media screen and (max-width: 1300px) {
  .header{
    &-l{
      &-txt{
        font-size: 18px;
      }
    }
    &-btn{
      margin-left: 15px;
      font-size: 16px;
    }
    &-menu{
      &-lnk{
        font-size: 16px;
      }
    }
  }
  .select2-container .select2-selection--single .select2-selection__rendered {
    font-size: 14px;
  }
}
@media screen and (max-width: 1200px) {
  .header{
    &-l{
      &-txt{
        width: 90px;
      }
    }
    &-menu{
      align-self: center;
    }
    &-r{
      align-self: baseline;
    }
  }
}
@media screen and (max-width: 1024px) {
  .menu-mob{
    display: block;
  }
  .header{
    border: 1px solid rgba($c-inverse, .12);
    &.__fixed{
      .header{
        &-logo{
          width: 55px;
          height: 35px;
        }
        &-cnt{
          padding: 18px 0;
        }
        &-menu{
          padding-left: 0;
        }
        &-btn{
          padding: 25px 50px 25px 30px;
        }
      }
    }
    &-logo{
      width: 55px;
      height: 35px;
      margin-top: 5px;
    }
    &-l{
      position: relative;
      z-index: 11;
      &-txt{
        font-size: 12px;
        margin-left: 15px;
        width: 60px;
        opacity: .66;
      }
    }
    &-cnt{
      position: relative;
      padding: 18px 0;
      align-items: center;
    }
    &-r{
      display: none;
    }
    &-menu{
      padding-left: 0;
      flex-grow: unset;
      order: 1;
      &-lst{
        display: none;
      }
    }
    .main-b{
      &-r{
        position: relative;
        display: flex;
        margin-right: 15px;
        z-index: 11;
      }
      &-btn{
        width: 56px;
        height: 56px;
        line-height: 56px;
        font-size: 16px;
        border-color: $c-inverse;
        &:hover{
          background: transparent;
          border-color: $c-inverse;
          color: $c-inverse;
        }
        &.__active{
          background: $c-inverse;
          border-color: $c-inverse;
          color: $c-primary;
        }
      }
    }
    &-btn{
      display: inline-block;
      margin-left: 0;
      background: $c-inverse;
      color: $c-primary;
      font-size: 26px;
      font-weight: 500;
      &-mob{
        display: block;
        margin-top: 30px;
      }
    }
  }
}
@media screen and (max-width: 680px) {
  .header{
    &-l{
      &-txt{
        display: none;
      }
    }
  }
}