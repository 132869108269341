img{
  display: block;
  max-width: 100%;
}
a{
  color: inherit;
  text-decoration: none;
  outline: none;
  transition: all 0.3s;
}
.clr:after{
  content:'';
  display: table;
  clear: both;
}
.__hidden{
  display: none;
}
.tel{
  pointer-events: none;
  cursor: default;
}
.__disable{
  pointer-events: none;
  cursor: default;
  opacity: .5;
}
.center-ic{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}
.__pl{
  padding-left: 12%;
}
.__pt-0{
  padding-top: 0!important;
}
.pl-0{
  padding-left: 0!important;
}
.pr-0{
  padding-right: 0!important;
}
.p-r{
  position: relative;
}
.__transparent{
  background: transparent!important;
}
.txt-dark{
  color: $c-base!important;
}
.__visible_hidden{
  visibility: hidden;
}

.content{
  content: '';
  display: block;
  position: absolute;
}
.z-1{
  z-index: 1;
}
.w-100{
  width: 100%;
}
.lnk{
  border-bottom: 1px solid $c-base;
}

.page{
  font-weight: 500;
  font-size: 21px;
  text-transform: uppercase;
  margin-bottom: 45px;
}
/* INPUTS */
@import "inputs";
/* END INPUTS */

/* BUTTONS */
@import "buttons";
/* END BUTTONS */

/* TITLES */
@import "titles";
/* END TITLES */


@media screen and (max-width: 900px){
  .tel{
    pointer-events: auto;
    cursor: pointer;
  }
}
@media screen and (max-width: 680px) {
  .page{
    font-size: 16px;
    margin-bottom: 20px;
  }
}
