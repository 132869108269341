.contacts{
  position: relative;
  padding: 0;
  color: $c-inverse;
  margin-top: -60px;
  &-cnt{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  &-l{
    padding: 240px 0 195px;
    background: $c-primary;
    flex-grow: 1;
    flex-shrink: 0;
    &-cnt{
      position: relative;
      padding-left: 200px;
      &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 110px;
        height: 2px;
        max-width: 130px;
        width: 100%;
        background: $c-inverse;
      }
    }
  }
  &-r{
    background-image: url("../img/contacts.png");
    height: 975px;
    width: 840px;
    background-size: cover;
    background-position: center right;
  }
  &-logo{
    margin-bottom: 40px;
    width: 100%;
  }
  &-ttl{
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 600;
    font-size: 72px;
    padding-top: 75px;
    margin-left: 203px;
  }
  &-i{
    margin-bottom: 60px;
    &-ttl{
      position: relative;
      font-weight: 600;
      font-size: 24px;
      padding-left: 22px;
      text-transform: uppercase;
      &:before{
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto 0;
      }
      &.__user{
        &:before{
          @extend .s-user;
        }
      }
      &.__address{
        &:before{
          @extend .s-address;
        }
      }
      &.__email{
        &:before{
          @extend .s-email;
        }
      }
      &.__fax{
        &:before{
          @extend .s-fax;
        }
      }
      &.__phone{
        &:before{
          @extend .s-phone;
        }
      }
    }
    &-txt{
      font-weight: 500;
      font-size: 26px;
      line-height: 1.4;
      margin-top: 25px;
    }
  }
  &-row{
    display: flex;
    justify-content: space-between;
  }
  &-col{
    &:first-of-type{
      margin-right: 10px;
    }
  }
}
@media screen and (max-width: 1100px) {
  .contacts{
    &-ttl{
      padding-top: 38px;
      margin-left: 90px;
    }
    &-l{
      padding: 60px 0 55px;
      flex-shrink: unset;
      &-cnt{
        padding-left: 90px;
        &:before{
          margin-top: 75px;
          max-width: 66px;
        }
      }
    }
    &-r{
      height: 739px;
    }
    &-i{
      &-ttl,
      &-txt{
        font-size: 21px;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .contacts{
    &-ttl{
      font-size: 33px;
    }
    &-logo{
      max-width: 230px;
    }
    &-i{
      margin-bottom: 40px;
      &-txt{
        margin-top: 20px;
      }
    }
    &-r{
      display: none;
    }
    &-l{
      &-cnt{
        &:before{
          margin-top: 55px;
        }
      }
    }
    &-row{
      justify-content: flex-start;
    }
    &-col:first-of-type {
      margin-right: 10%;
    }
  }
}
@media screen and (max-width: 680px){
  .contacts{
    &-row{
      flex-wrap: wrap;
    }
    &-col{
      width: 100%;
      margin: 0;
    }
    &-l{
      padding: 60px 0 15px;
      &-cnt{
        padding-left: 0;
        &:before{
          margin-top: -15px;
        }
      }
    }
    &-ttl{
      padding-top: 30px;
      margin-left: 0;
    }
    &-i{
      &-ttl,
      &-txt{
        font-size: 20px;
      }
    }
  }
}