.price{
  position: relative;
  padding: 170px 0 0;
  z-index: 1;
  &-cnt{
    display: flex;
    justify-content: space-between;
    &-l{
      background-image: url("../img/price.png");
      width: 100%;
      max-width: 790px;
      height: 1200px;
      background-size: cover;
      background-position: center;
      &.__mob{
        display: none;
      }
    }
    &-r{
      padding: 100px 0 100px 60px;
    }
  }
  &-form{
    margin-top: 90px;
    &-row{
      @extend .row;
      margin-left: -20px;
      margin-right: -20px;
    }
    &-col{
      @include make-col-ready();
      @include make-col(6);
      padding: 20px;
    }
    &-btn{
      border-color: $c-primary;
      background: $c-primary;
      max-width: 100%;
      width: 100%;
      margin-right: 7%;
      font-size: 26px;
      font-weight: 500;
      padding: 35px 15px 34px 15px;
      &:after{
        display: none;
      }
      &:hover{
        color: $c-base;
        border-color: $c-base;
        background: transparent;
        .price-form-ic{
          &:after{
            @extend .s-arrow-dark;
          }
        }
      }
    }
    &-ic{
      position: relative;
      padding-right: 30px;
      &:after{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto 0;
        @extend .s-arrow-inverse;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .price {
    padding: 80px 0 0;
    &-cnt{
      flex-wrap: wrap;
      &-l{
        display: none;
        max-width: 100%;
        height: 500px;
        &.__mob{
          display: block;
          margin-top: 60px;
        }
      }
      &-r{
        padding: 0 0 100px 40px;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .price {
    &-form{
      margin-top: 30px;
    }
  }
}
@media screen and (max-width: 800px) {
  .price{
    &-form{
      &-row{
        margin: 0;
      }
      &-col{
        @include make-col(12);
        padding: 0;
        &:nth-of-type(3){
          order: 1;
        }
      }
      &-btn{
        font-size: 21px;
        padding: 29px 15px 29px 15px;
      }
    }
    &-cnt{
      &-r{
        padding: 0 0 100px 20px;
      }
      &-l{
        background-position: top;
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .price {
    &-cnt{
      &-l{
        height: 200px;
        background-position: 80% 18%;
      }
    }
  }
}
@media screen and (max-width: 580px) {
  .price {
    &-form {
      &-col{
        &:nth-of-type(3){
          margin-top: 40px;
          order: 1;
        }
      }
    }
  }
}