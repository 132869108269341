// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$s-address-name: 's-address';
$s-address-x: 38px;
$s-address-y: 241px;
$s-address-offset-x: -38px;
$s-address-offset-y: -241px;
$s-address-width: 12px;
$s-address-height: 16px;
$s-address-total-width: 565px;
$s-address-total-height: 264px;
$s-address-image: '../img/sprites/sprite.png';
$s-address: (38px, 241px, -38px, -241px, 12px, 16px, 565px, 264px, '../img/sprites/sprite.png', 's-address', );
$s-arrow-dark-name: 's-arrow-dark';
$s-arrow-dark-x: 54px;
$s-arrow-dark-y: 225px;
$s-arrow-dark-offset-x: -54px;
$s-arrow-dark-offset-y: -225px;
$s-arrow-dark-width: 15px;
$s-arrow-dark-height: 16px;
$s-arrow-dark-total-width: 565px;
$s-arrow-dark-total-height: 264px;
$s-arrow-dark-image: '../img/sprites/sprite.png';
$s-arrow-dark: (54px, 225px, -54px, -225px, 15px, 16px, 565px, 264px, '../img/sprites/sprite.png', 's-arrow-dark', );
$s-arrow-inverse-name: 's-arrow-inverse';
$s-arrow-inverse-x: 69px;
$s-arrow-inverse-y: 225px;
$s-arrow-inverse-offset-x: -69px;
$s-arrow-inverse-offset-y: -225px;
$s-arrow-inverse-width: 15px;
$s-arrow-inverse-height: 15px;
$s-arrow-inverse-total-width: 565px;
$s-arrow-inverse-total-height: 264px;
$s-arrow-inverse-image: '../img/sprites/sprite.png';
$s-arrow-inverse: (69px, 225px, -69px, -225px, 15px, 15px, 565px, 264px, '../img/sprites/sprite.png', 's-arrow-inverse', );
$s-arrow-primary-name: 's-arrow-primary';
$s-arrow-primary-x: 38px;
$s-arrow-primary-y: 225px;
$s-arrow-primary-offset-x: -38px;
$s-arrow-primary-offset-y: -225px;
$s-arrow-primary-width: 16px;
$s-arrow-primary-height: 16px;
$s-arrow-primary-total-width: 565px;
$s-arrow-primary-total-height: 264px;
$s-arrow-primary-image: '../img/sprites/sprite.png';
$s-arrow-primary: (38px, 225px, -38px, -225px, 16px, 16px, 565px, 264px, '../img/sprites/sprite.png', 's-arrow-primary', );
$s-arrow-s-name: 's-arrow-s';
$s-arrow-s-x: 138px;
$s-arrow-s-y: 225px;
$s-arrow-s-offset-x: -138px;
$s-arrow-s-offset-y: -225px;
$s-arrow-s-width: 10px;
$s-arrow-s-height: 10px;
$s-arrow-s-total-width: 565px;
$s-arrow-s-total-height: 264px;
$s-arrow-s-image: '../img/sprites/sprite.png';
$s-arrow-s: (138px, 225px, -138px, -225px, 10px, 10px, 565px, 264px, '../img/sprites/sprite.png', 's-arrow-s', );
$s-arrow-name: 's-arrow';
$s-arrow-x: 148px;
$s-arrow-y: 225px;
$s-arrow-offset-x: -148px;
$s-arrow-offset-y: -225px;
$s-arrow-width: 10px;
$s-arrow-height: 10px;
$s-arrow-total-width: 565px;
$s-arrow-total-height: 264px;
$s-arrow-image: '../img/sprites/sprite.png';
$s-arrow: (148px, 225px, -148px, -225px, 10px, 10px, 565px, 264px, '../img/sprites/sprite.png', 's-arrow', );
$s-contacts-name: 's-contacts';
$s-contacts-x: 0px;
$s-contacts-y: 0px;
$s-contacts-offset-x: 0px;
$s-contacts-offset-y: 0px;
$s-contacts-width: 565px;
$s-contacts-height: 225px;
$s-contacts-total-width: 565px;
$s-contacts-total-height: 264px;
$s-contacts-image: '../img/sprites/sprite.png';
$s-contacts: (0px, 0px, 0px, 0px, 565px, 225px, 565px, 264px, '../img/sprites/sprite.png', 's-contacts', );
$s-email-name: 's-email';
$s-email-x: 84px;
$s-email-y: 225px;
$s-email-offset-x: -84px;
$s-email-offset-y: -225px;
$s-email-width: 14px;
$s-email-height: 14px;
$s-email-total-width: 565px;
$s-email-total-height: 264px;
$s-email-image: '../img/sprites/sprite.png';
$s-email: (84px, 225px, -84px, -225px, 14px, 14px, 565px, 264px, '../img/sprites/sprite.png', 's-email', );
$s-fax-name: 's-fax';
$s-fax-x: 98px;
$s-fax-y: 225px;
$s-fax-offset-x: -98px;
$s-fax-offset-y: -225px;
$s-fax-width: 14px;
$s-fax-height: 14px;
$s-fax-total-width: 565px;
$s-fax-total-height: 264px;
$s-fax-image: '../img/sprites/sprite.png';
$s-fax: (98px, 225px, -98px, -225px, 14px, 14px, 565px, 264px, '../img/sprites/sprite.png', 's-fax', );
$s-main-arr-name: 's-main-arr';
$s-main-arr-x: 22px;
$s-main-arr-y: 225px;
$s-main-arr-offset-x: -22px;
$s-main-arr-offset-y: -225px;
$s-main-arr-width: 16px;
$s-main-arr-height: 38px;
$s-main-arr-total-width: 565px;
$s-main-arr-total-height: 264px;
$s-main-arr-image: '../img/sprites/sprite.png';
$s-main-arr: (22px, 225px, -22px, -225px, 16px, 38px, 565px, 264px, '../img/sprites/sprite.png', 's-main-arr', );
$s-phone-name: 's-phone';
$s-phone-x: 112px;
$s-phone-y: 225px;
$s-phone-offset-x: -112px;
$s-phone-offset-y: -225px;
$s-phone-width: 14px;
$s-phone-height: 14px;
$s-phone-total-width: 565px;
$s-phone-total-height: 264px;
$s-phone-image: '../img/sprites/sprite.png';
$s-phone: (112px, 225px, -112px, -225px, 14px, 14px, 565px, 264px, '../img/sprites/sprite.png', 's-phone', );
$s-slider-arr-name: 's-slider-arr';
$s-slider-arr-x: 0px;
$s-slider-arr-y: 225px;
$s-slider-arr-offset-x: 0px;
$s-slider-arr-offset-y: -225px;
$s-slider-arr-width: 22px;
$s-slider-arr-height: 39px;
$s-slider-arr-total-width: 565px;
$s-slider-arr-total-height: 264px;
$s-slider-arr-image: '../img/sprites/sprite.png';
$s-slider-arr: (0px, 225px, 0px, -225px, 22px, 39px, 565px, 264px, '../img/sprites/sprite.png', 's-slider-arr', );
$s-user-name: 's-user';
$s-user-x: 126px;
$s-user-y: 225px;
$s-user-offset-x: -126px;
$s-user-offset-y: -225px;
$s-user-width: 12px;
$s-user-height: 14px;
$s-user-total-width: 565px;
$s-user-total-height: 264px;
$s-user-image: '../img/sprites/sprite.png';
$s-user: (126px, 225px, -126px, -225px, 12px, 14px, 565px, 264px, '../img/sprites/sprite.png', 's-user', );
$spritesheet-width: 565px;
$spritesheet-height: 264px;
$spritesheet-image: '../img/sprites/sprite.png';
$spritesheet-sprites: ($s-address, $s-arrow-dark, $s-arrow-inverse, $s-arrow-primary, $s-arrow-s, $s-arrow, $s-contacts, $s-email, $s-fax, $s-main-arr, $s-phone, $s-slider-arr, $s-user, );
$spritesheet: (565px, 264px, '../img/sprites/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
