.work{
  padding: 170px 0 0;
  &-wrap{
    margin-top: 50px;
  }
  &-row{
    display: flex;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
  }
  &-card{
    padding: 65px;
    height: 100%;
    color: $c-base;
    border: 2px solid rgba($c-base, .12);
    transition: all .1s;
    &:hover{
      color: $c-inverse;
      background: $c-primary;
      border-color: $c-primary;
      .work{
        &-card{
          &-ic{
            display: none;
            &.__hover{
              display: block;
            }
          }
          &-number{
            color: $c-inverse;
          }
          &-lnk{
            &:after{
              @extend .s-arrow-inverse!optional;
            }
          }
        }
      }
    }
    &-t{
      height: 100px;
      display: flex;
      align-items: center;
      margin-bottom: 40px;
    }
    &-ic{
      &.__hover{
        display: none;
      }
    }
    &-ttl{
      font-weight: 600;
      font-size: 36px;
    }
    &-txt{
      font-weight: 500;
      font-size: 26px;
      line-height: 1.3;
      padding: 20px 0 50px;
    }
    &-b{
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
    &-number{
      font-weight: 600;
      font-size: 36px;
      color: $c-primary;
      margin-right: 15px;
      &.__mob{
        display: none;
      }
    }
    &-lnk{
      display: block;
      position: relative;
      font-weight: 500;
      font-size: 26px;
      padding-right: 25px;
      transition: none;
      &:after{
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 3px;
        margin: 0;
        @extend .s-arrow-dark!optional;
      }
      &:hover{
        text-decoration: underline;
      }
    }
    &-cnt{
      position: relative;
      height: 100%;
      padding-bottom: 35px;
    }
    &-b{
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}
.col-sm {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding: 10px;
  width: 100%;
  min-height: 1px;
}
.col-sm:first-of-type {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-sm:nth-of-type(2) {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
@media screen and (max-width: 1024px) {
  .work {
    padding: 80px 0 0;
    &-card{
      padding: 40px;
    }
  }
  .col-sm:first-of-type {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm:nth-of-type(2) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media screen and (max-width: 820px){
  .col-sm {
    flex-basis: 100%;
    flex-grow: 1;
    max-width: 100%;
    padding: 10px;
    &:first-of-type{
      .work-card-b{
        display: block;
      }
    }
  }
  .col-sm:first-of-type {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-sm:nth-of-type(2) {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .work {
    &-card{
      padding: 15px 40px 40px;
      &-number{
        display: none;
        margin-top: 20px;
        margin-right: 0;
        font-size: 27px;
        &.__mob{
          display: block;
        }
      }
      &-cnt{
        padding-bottom: 0;
      }
      &-top{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
      &-t{
        margin-bottom: 20px;
        width: 50px;
      }
      &-ttl{
        font-size: 23px;
      }
      &-txt{
        font-size: 16px;
        padding: 20px 0;
      }
      &-lnk{
        font-size: 19px;
        display: inline-block;
      }
      &-b{
        display: none;
        position: static;
      }
    }
  }
}