.main{
  position: relative;
  height: 1259px;
  background-size: cover;
  &-cnt{
    font-size: 100px;
    font-weight: 600;
    padding-top: 20%;
    color: $c-inverse;
    margin-left: 14%;
    &-ttl{
      color: $c-primary;
    }
  }
  &-blk{
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }
  &-b{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0 90px;
    &-arr{
      position: relative;
      display: inline-block;
      border-radius: 50%;
      border: 1px solid $c-primary;
      background: $c-primary;
      transition: all .2s;
      width: 86px;
      height: 86px;
      line-height: 86px;
      cursor: pointer;
      flex-shrink: 0;
      &:after{
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        @extend .s-main-arr;
      }
      &:hover{
        background: transparent;
        border-color: $c-inverse;
      }
    }
    &-l{
      display: flex;
      align-items: center;
    }
    &-lnk{
      display: block;
      position: relative;
      color: $c-inverse;
      font-weight: 600;
      font-size: 21px;
      margin-left: 40%;
      padding-bottom: 10px;
      flex-shrink: 0;
      &:hover{
        &:after{
          content: '';
          position: absolute;
          display: block;
          background: $c-inverse;
          right: 0;
          bottom: 0;
          left: 0;
          height: 1px;
          width: 101px;
        }
      }
    }
    &-r{
      display: flex;
      align-items: center;
    }
    &-btn{
      display: block;
      border-radius: 50%;
      border: 1px solid transparent;
      background: transparent;
      width: 86px;
      height: 86px;
      line-height: 86px;
      cursor: pointer;
      text-align: center;
      color: $c-inverse;
      font-size: 21px;
      font-weight: 500;
      transition: all .2s;
      flex-shrink: 0;
      &:first-of-type{
        margin: 0 15px;
      }
      &:hover,
      &.__active{
        border-color: $c-inverse;
        background: $c-inverse;
        color: $c-base;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .main{
    height: 900px;
    &-b{
      &-r{
        display: none;
      }
      &-lnk{
        margin-left: 25px;
      }
    }
    &-cnt{
      font-size: 70px;
      margin-left: 0;
      padding-top: 150px;
    }
  }
}
@media screen and (max-width: 680px) {
  .main{
    height: 700px;
    background-position: 72%;
    &-cnt {
      font-size: 36px;
      line-height: 1.2;
    }
    &-b{
      padding: 20px 0 70px;
      &-lnk{
        margin-left: 25px;
        max-width: 140px;
        width: 100%;
        line-height: 1.2;
      }
    }
  }
}