.footer{
  .header{
    &-menu{
      font-weight: 400;
      font-size: 21px;
      &-lst{
        justify-content: center;
      }
      &-lnk{
        &:after{
          background-color: $c-base;
          opacity: 1;
        }
      }
    }
    &-cnt{
      padding: 70px 0;
    }
    &-btn{
      background: $c-primary;
      border-color: $c-primary;
      &:hover{
        background: transparent;
        border-color: $c-base;
        color: $c-base;
        &:after{
          @extend .s-arrow-s;
        }
      }
    }
  }
  .price-form-btn{
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .footer{
    .header{
      &-menu{
        flex-grow: 1;
        order: 0;
        &-lst{
          display: flex;
          margin: 0 15px;
        }
        &-lnk{
          padding: 0;
        }
      }
      &-r{
        display: block;
      }
      &-btn{
        font-size: 18px;
        color: $c-inverse;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .footer{
    .header{
      &-logo{
        width: 126px;
        height: auto;
        margin-top: 0;
      }
      &-cnt{
        padding: 100px 0 55px;
        flex-wrap: wrap;
        justify-content: center;
      }
      &-l{
        width: 100%;
        justify-content: center;
      }
      &-menu{
        flex-wrap: wrap;
        padding: 30px 0 50px;
        &-lst{
          flex-wrap: wrap;
          justify-content: center;
        }
        &-i{
          padding: 20px 0;
          width: 100%;
          text-align: center;
        }
        &-lnk{
          display: inline-block;
          font-size: 27px;
        }
      }
      &-r{
        width: 100%;
        text-align: center;
      }
      &-btn{
        display: none;
      }
    }
    .price-form-btn{
      display: block;
    }
  }
}