.btn{
  position: relative;
  display: block;
  font-size: 18px;
  font-weight: 400;
  color: $c-inverse;
  text-align: center;
  padding: 22px 40px 22px 35px;
  border: 1px solid rgba($c-inverse, .2);
  border-radius: 172px;
  background: transparent;
  &:after{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto 20px auto;
    @extend .s-arrow!optional;
  }
  &:hover{
    background: $c-primary;
    border-color: $c-primary;
  }
}
.btn-primary{
  @extend .btn;
  background: $c-primary;
  border-color: $c-primary;
  &:hover{
    //background: $c-bg-dark;
    //border-color: $c-border;
  }
}