input, textarea{
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid $c-desc;
  background: $c-inverse;
  color: $c-base;
  padding: 20px 0;
  font-family: $base-font;
  font-weight: 500;
  font-size: 21px;
  text-transform: uppercase;
  outline: none;
  &:focus{
    outline: none;
    color: $c-base;
    border-color: $c-desc;
  }
  &::-webkit-input-placeholder {
    font-family: $base-font;
    font-weight: 500;
    font-size: 21px;
    text-transform: uppercase;
    color: $c-desc;
  }
  &::-moz-placeholder{
    font-family: $base-font;
    font-weight: 500;
    font-size: 21px;
    text-transform: uppercase;
    color: $c-desc;
  }/* Firefox 19+ */
  &:-moz-placeholder{
    font-family: $base-font;
    font-weight: 500;
    font-size: 21px;
    text-transform: uppercase;
    color: $c-desc;
  }/* Firefox 18- */
  &:-ms-input-placeholder{
    font-family: $base-font;
    font-weight: 500;
    font-size: 21px;
    text-transform: uppercase;
    color: $c-desc;
  }
}

input[type='submit'],
button[type='submit']{
  outline: none;
  cursor: pointer;
  //@extend .btn;
}
.wrap-inp{
  position: relative;
  height: 67px;
}

.inp-order-lbl{
  font-weight: 500;
  font-size: 21px;
  text-transform: uppercase;
  color: $c-desc;
  position: absolute;
  transform-origin: 0 50%;
  transition: transform 200ms, color 200ms;
  top: 20px;
  left: 0;
  line-height: 21px;
}
input{
  height: 100%;
}
input:focus ~ .inp-order-lbl,
input:not(:placeholder-shown) ~ .inp-order-lbl {
  transform: translateY(-30px) translateX(0) scale(0.75);
}

textarea{
  resize: vertical;
  padding: 10px;
}
.wrap-inp{
  margin-bottom: 45px;
  &:last-of-type{
    margin-bottom: 15px;
  }
}
.wrap-sub{
  margin-top: 40px;
}
.wrap-select {
  .select2-container{
    width: 100%!important;
  }
  .select2-container--default .select2-selection--single{
    //border: 1px solid $c-inp;
    //background: $c-inp;
    color: $c-desc;
    padding: 20px;
    font-family: $base-font;
    font-weight: 400;
    font-size: 14px;
    border-radius: 120px;
    height: 59px;
  }
  .select2-container .select2-selection--single .select2-selection__rendered{
    font-family: $base-font;
    font-weight: 400;
    font-size: 14px;
    color: $c-desc;
    line-height: 1.2;
    text-transform: none;
    padding-left: 0;
  }
  .s-select, .select2-container .select2-selection--single:after{
    top: 0;
    bottom: 0;
    margin: auto 15px auto 0;
  }
  .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple, .select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
    border-radius: 30px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple, .select2-container--default.select2-container--open.select2-container--above .select2-selection--single{
    border-radius: 30px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .select2-dropdown {
    //background-color: $c-inp;
    top: 0;
  }
  .select2-results{
    font-weight: 400;
    font-size: 14px;
    color: $c-desc;
    line-height: 1.2;
  }
  .select2-results__option {
    padding: 10px 0 10px 20px;
  }
  .select2-container--default .select2-results>.select2-results__options {
    max-height: 100%;
  }
}
#form-status{
  margin-top: 20px;
  width: 100%;
  text-align: center;
}
#form-status.with_error{
  color: red;
}
.error{
  //border-color: $c-primary!important;
}

.inp-lbl{
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 25px;
}

/* checkbox */
.checkbox{
  display: none;
  &:checked+.checkbox-lbl{
    &:before{
      @extend .content;
      top: 9px;
      left: 0;
      margin: 0 5px;
      z-index: 1;
      @extend .s-check!optional;
    }
    &:after{
      background: $c-primary;
      border-color: $c-primary;
    }
  }
  &.error+.checkbox-lbl{
    &:after{
      border-color: red;
    }
  }
  &-lbl{
    cursor: pointer;
    display: block;
    text-align: left;
    padding-left: 50px;
    position: relative;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.2;
    &:before{
      display: none;
    }
    &:after{
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      top: 3px;
      width: 31px;
      height: 31px;;
      background: $c-desc-light;
      border: 1px solid $c-desc-light;
      border-radius: 50%;
      transition: background .2s;
    }
  }
  &-lnk{
    color: $c-primary;
    padding-bottom: 3px;
    border-bottom: 2px solid transparent;
    &:hover{
      border-color: $c-primary;
    }
  }
}
@media screen and (max-width: 1024px) {
  .wrap-inp {
    height: 55px;
    margin-bottom: 20px;
  }
  .inp-order-lbl,
  input, textarea{
    font-size: 16px;
  }
}
@media screen and (max-width: 800px) {
  .checkbox-lbl{
    font-size: 18px;
    padding-left: 40px;
    margin: 30px 0 30px;
    &:after{
      width: 24px;
      height: 24px;
    }
  }
}