.about{
  padding: 170px 0 0;
  &-row{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &-l{
    max-width: 850px;
    margin-right: 30px;
  }
  &-r{
    max-width: 645px;
  }
  &-txt{
    font-weight: 500;
    font-size: 24px;
    line-height: 1.3;
    margin-top: 90px;
  }
  &-img{
    max-width: 348px;
    width: 100%;
  }
  &-blk{
    margin-top: 100px;
    &-wrap{
      position: relative;
    }
  }
  &-slider{
    &-i{
      height: 614px;
      background-size: cover;
    }
    &-nav{
      &-btn{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto 0 auto 30px;
        display: block;
        border-radius: 50%;
        border: 1px solid $c-inverse;
        transition: all .2s;
        width: 140px;
        height: 140px;
        line-height: 140px;
        cursor: pointer;
        z-index: 2;
        &:after{
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          margin: auto;
          @extend .s-slider-arr;
        }
        &.__next{
          right: 0;
          left: auto;
          margin: auto 30px auto 0;
          &:after{
            transform: rotate(180deg);
          }
        }
        &:hover{
          border-color: $c-primary;
          background: $c-primary;
        }
      }
    }
    .owl-item.__last{
      .team{
        &-slider{
          &-i{
            pointer-events: none;
            opacity: 0.12;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .about {
    &-row{
      align-items: flex-start;
    }
    &-l{
      margin-right: 30px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .about {
    padding: 80px 0 0;
    &-img{
      max-width: 230px;
    }
    &-slider{
      &-i{
        height: 300px;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .about {
    &-row{
      flex-wrap: wrap;
    }
    &-img{
      max-width: 230px;
    }
    &-txt{
      margin-top: 40px;
      font-size: 20px;
    }
    &-r{
      max-width: 100%;
      margin-top: 50px;
    }
    &-blk{
      margin-top: 50px;
    }
    &-slider{
      &-nav-btn{
        width: 51px;
        height: 51px;
        line-height: 51px;
        &:after{
          background-image: url("../img/about/arr-slider.png");
          background-repeat: no-repeat;
          background-position: center;
          transform: rotate(180deg);
        }
        &.__next{
          &:after {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}
@media screen and (max-width: 680px) {
  .about{
    &-blk{
      padding: 0 20px;
    }
    &-slider{
      &-i{
        height: 200px;
        background-position: center;
      }
      &-nav-btn{
        &:not(.__next){
          display: none;
        }
        &.__next{
          margin: auto 20px auto 0;
        }
      }
    }
  }
}