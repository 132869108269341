.ttl{
  font-weight: 600;
  font-size: 72px;
  line-height: 1.1;
}
@media screen and (max-width: 1200px){
  .ttl{
    font-size: 42px;
  }
}
@media screen and (max-width: 800px){
  .ttl{
    font-size: 35px;
  }
}
@media screen and (max-width: 680px) {
  .ttl {
    font-size: 32px;
  }
}